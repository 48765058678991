import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectProjectIntegrations } from "@/store/integrations/integrations-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { Option } from "@faro-lotv/flat-ui";
import { PropsWithChildren, useState } from "react";
import { AnnotationWithNewAttachments } from "./annotation-props";
import { ExternalAnnotation } from "./external-annotation";
import {
  getAllBcfServicesIntegrationTypes,
  getOptionDataFromBcfIntegrationType,
} from "./external-annotation-utils";
import { SphereAnnotation } from "./sphere-annotation";

export interface CreateAnnotationFormProps {
  /** Callback called when the dialog is closed (successfully or non-successfully). use to clear the annotationToCreate. */
  onClose(): void;

  /** Callback called when the user click the save button */
  onSave(details: AnnotationWithNewAttachments): Promise<void>;
}
/** The annotation type value that is corresponding to Sphere XG annotation */
const SPHERE_XG_ANNOTATION_VALUE = "FARO Sphere XG";

/**
 * @returns A form to persist an annotation to the project
 */
export function CreateAnnotationForm({
  children,
  onClose,
  onSave,
}: PropsWithChildren<CreateAnnotationFormProps>): JSX.Element {
  const [annotationType, setAnnotationType] = useState(
    SPHERE_XG_ANNOTATION_VALUE,
  );

  const projectIntegrations = useAppSelector(selectProjectIntegrations);
  const allProjectBcfIntegrationTypes = projectIntegrations
    .map((integration) => getAllBcfServicesIntegrationTypes(integration))
    .flat();
  const annotationTypeOptions: Option[] = allProjectBcfIntegrationTypes.map(
    (bcfIntegrationType) =>
      getOptionDataFromBcfIntegrationType(bcfIntegrationType),
  );
  annotationTypeOptions.push({
    key: "",
    value: SPHERE_XG_ANNOTATION_VALUE,
    label: SPHERE_XG_ANNOTATION_VALUE,
  });

  const externalMarkupCreationEnabled = useAppSelector(
    selectHasFeature(Features.ThirdPartyAnnotation),
  );

  // We are checking that children is undefined because we don't want to show external markup selection dropdown
  // when creating a new annotation with custom fields like the one created from measurements
  const enableExternalMarkups =
    externalMarkupCreationEnabled &&
    annotationTypeOptions.length > 0 &&
    children === undefined;

  if (!enableExternalMarkups || annotationType === SPHERE_XG_ANNOTATION_VALUE) {
    return (
      <SphereAnnotation
        onClose={onClose}
        onSave={onSave}
        enableExternalMarkups={enableExternalMarkups}
        annotationType={annotationType}
        onAnnotationTypeChange={setAnnotationType}
        annotationTypeOptions={annotationTypeOptions}
      >
        {children}
      </SphereAnnotation>
    );
  }
  return (
    <ExternalAnnotation
      onClose={onClose}
      onSave={onSave}
      enableExternalMarkups={enableExternalMarkups}
      annotationType={annotationType}
      onAnnotationTypeChange={setAnnotationType}
      annotationTypeOptions={annotationTypeOptions}
    />
  );
}
