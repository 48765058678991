import { Option } from "@faro-lotv/flat-ui";
import {
  ExternalMarkupIElement,
  IElementType,
} from "@faro-lotv/ielement-types";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";

type IntegrationsDataAssociations = {
  /** The "BCF Services" integration type */
  bcfIntegrationType: BcfServicesIntegrationType;
  /** The dropdown option data corresponding to "BCF Services" integration type */
  annotationTypeOption: Option;
  /** The iElementType corresponding to the "BCF Services" integration type */
  iElementType: ExternalMarkupIElement["type"];
  /** The name of the integration provider */
  providerName: string;
};

const integrationsDataAssociations: IntegrationsDataAssociations[] = [
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskAccIssues,
    annotationTypeOption: {
      key: "autodeskAccIssue",
      value: "Autodesk ACC Issue",
      label: "Autodesk ACC Issue",
    },
    iElementType: IElementType.markupAccIssue,
    providerName: "autodesk",
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskAccRfis,
    annotationTypeOption: {
      key: "autodeskAccRfi",
      value: "Autodesk ACC RFI",
      label: "Autodesk ACC RFI",
    },
    iElementType: IElementType.markupAccRfi,
    providerName: "autodesk",
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskBim360Issues,
    annotationTypeOption: {
      key: "autodeskBim360Issue",
      value: "Autodesk BIM360 Issue",
      label: "Autodesk BIM360 Issue",
    },
    iElementType: IElementType.markupBim360,
    providerName: "autodesk",
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.procoreObservations,
    annotationTypeOption: {
      key: "procoreObservation",
      value: "Procore Observation",
      label: "Procore Observation",
    },
    iElementType: IElementType.markupProcoreObservation,
    providerName: "procore",
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.procoreRfis,
    annotationTypeOption: {
      key: "procoreRfi",
      value: "Procore RFI",
      label: "Procore RFI",
    },
    iElementType: IElementType.markupProcoreRfi,
    providerName: "procore",
  },
];

/**
 * @param bcfType The "BCF Services" integration type
 * @returns dropdown option data that corresponds to the given "BCF Services" integration type
 */
export function getOptionDataFromBcfIntegrationType(
  bcfType: BcfServicesIntegrationType,
): Option {
  const association = integrationsDataAssociations.find(
    (association) => association.bcfIntegrationType === bcfType,
  );
  if (!association) {
    throw new Error("Unknown integration");
  }
  return association.annotationTypeOption;
}

/**
 * @param providerName The name of the integration provider
 * @returns all "BCF Services" integration types for the given integration provider
 */
export function getAllBcfServicesIntegrationTypes(
  providerName: string,
): BcfServicesIntegrationType[] {
  return integrationsDataAssociations
    .filter((association) => association.providerName === providerName)
    .map((association) => association.bcfIntegrationType);
}
